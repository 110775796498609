/** Easier to build placeholders with skeletonreact.com */
import ContentLoader from "react-content-loader";

export function Placeholder(props) {
  return (
    <ContentLoader
      speed={2}
      width={730}
      height={630}
      viewBox="0 0 730 630"
      backgroundColor="#ecebeb"
      foregroundColor="#cccccc"
      {...props}
    >
      <rect x="32" y="422" rx="0" ry="0" width="385" height="74" />
      <rect x="32" y="526" rx="0" ry="0" width="342" height="21" />
      <rect x="32" y="555" rx="0" ry="0" width="368" height="21" />
      <rect x="32" y="585" rx="0" ry="0" width="315" height="21" />
    </ContentLoader>
  );
}
