import PropTypes from "prop-types";

import { tagular } from "@cohesion/tagular";

import { BasicListCard } from "@components/cards/basicList";
import { Placeholder } from "./placeholder";

export function SecondaryCard({
  loading = false,
  tagularCtx = {},
  ...article
}) {
  if (loading) return <Placeholder />;

  return (
    <BasicListCard
      titleClamp={3}
      className="bg-[#F0F7FC] border border-[#E0EEFA] md:my-auto lg:h-[160px]"
      onClick={() => {
        tagular("click", {
          actionOutcome: "internallink",
          outboundUrl: article.href,
          webElement: {
            location: "section",
            position: "lateststories",
            text: article.title,
            elementType: "link",
            ...tagularCtx,
          },
        });
      }}
      {...article}
      {...{
        eyebrow: article.tags?.[0]?.title || "Articles",
      }}
    />
  );
}

SecondaryCard.propTypes = {
  loading: PropTypes.bool,
  tagularCtx: PropTypes.shape({}),
};
