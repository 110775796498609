import PropTypes from "prop-types";
import cn from "classnames";

export function Heading({ children, className = "", elem: Elem = "h2" }) {
  return (
    <Elem className={cn("text-2xl md:text-5xl md:leading-relaxed", className)}>
      {children}
    </Elem>
  );
}

Heading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    /**
     * String interpolation creates an array, such that `See ${location}`
     * arrives as ["See ", "Germany"]
     */
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  elem: PropTypes.oneOf(["h1", "h2", "h3"]),
};
