/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from "prop-types";
import cn from "classnames";
import { ImgixNextImage } from "@components/imgixNextImage";

import { Eyebrow } from "@components/eyebrow";

export function BasicListCard({
  eyebrow = null,
  className = "",
  description = null,
  title = "",
  subhead = null,
  image = {},
  href = null,
  onClick = () => {},
  titleClamp = 1,
  hasLargeImage = false,
  type = "general",
}) {
  const titleClampClasses = {
    1: "line-clamp-1",
    2: "line-clamp-2",
    3: "line-clamp-3",
    4: "line-clamp-4",
  };

  return (
    <article
      key={title}
      className={cn(
        "relative flex items-center rounded-md h-full p-4 hover:shadow-md focus:shadow-md md:my-6",
        { "bg-white": !className.includes("bg-") },
        className
      )}
      onClick={onClick}
      onKeyUp={e => (e.key === "Enter" ? onClick : null)}
    >
      <div
        className={cn(
          "flex-none mr-4 md:mr-6 rounded-md overflow-hidden",
          hasLargeImage ? "w-1/4" : "w-1/5"
        )}
      >
        <ImgixNextImage
          src={image?.url}
          alt={image?.alt}
          className="w-full h-full aspect-square"
          width={hasLargeImage ? 250 : 140}
          height={hasLargeImage ? 250 : 140}
          imgixParams={{
            w: hasLargeImage ? 250 : 140,
            h: hasLargeImage ? 250 : 140,
            fit: "crop",
          }}
          type={type}
        />
      </div>
      <div className="flex-auto w-0">
        {eyebrow && (
          <Eyebrow className="relative z-10 mb-2 w-90">{eyebrow}</Eyebrow>
        )}
        <a
          href={href}
          className={cn(
            "lg:text-lg text-black hover:text-black font-semibold card-link mb-1",
            titleClampClasses[titleClamp]
          )}
        >
          {title}
        </a>
        {subhead && <p className="text-sm text-black-400">{subhead}</p>}
        {(description?.text || description) && (
          <p className={cn("line-clamp-2 md:line-clamp-3 mt-2 text-black-400")}>
            <span
              className={cn({
                "hidden md:inline": description.hideMobile,
              })}
            >
              {description?.text || description}
            </span>
          </p>
        )}
      </div>
    </article>
  );
}

BasicListCard.propTypes = {
  eyebrow: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  className: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      hideMobile: PropTypes.bool, // hides description on mobile
      text: PropTypes.string,
    }),
    PropTypes.shape({}), // support for highlighted search results
  ]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}), // support for highlighted search results
  ]),
  subhead: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string,
  }),
  href: PropTypes.string,
  onClick: PropTypes.func,
  titleClamp: PropTypes.oneOf([1, 2, 3, 4]),
  hasLargeImage: PropTypes.bool,
  type: PropTypes.oneOf([
    "general",
    "destination",
    "attractions",
    "restaurants",
    "entertainment",
    "nightlife",
    "shopping",
    "hotels",
  ]),
};
