import first from "lodash/first";
import get from "lodash/get";
import format from "date-fns/format";
import { formatReadingTime } from "@utils/formatReadingTime";
import { LP_URL } from "@constants/lpUrl";

const articleListItemsTransformation = articles =>
  articles
    .filter(a => !!a) // remove null items from array
    .map(article => {
      const {
        authors = null,
        date,
        esid,
        excerpt,
        featuredImage = null,
        readTime,
        slug,
        tags,
        title,
        meta,
        ...rest
      } = article;

      // Published Date
      const publishDate = date ? format(new Date(date), "MMM d, yyyy") : null;
      const readingTime = formatReadingTime(readTime) || "";

      // Article Type
      const articleTypeSlug = meta?.type?.slug === "news" ? "news" : "articles";

      // Article Category
      const firstTag = first(tags);
      const tagTitle = get(firstTag, "title") || "";
      const tagSlug = get(firstTag, "slug") || "";
      const tagUrl = `${LP_URL}/articles/category/${tagSlug}`;

      // Article Author
      const author =
        authors && authors[0]
          ? authors.map(singleAuthor => {
              if (!singleAuthor) return [];
              const {
                firstName,
                lastName,
                slug: authorSlug,
                image,
              } = singleAuthor;

              const parsedAuthSlug =
                Array.from(authorSlug)[0] === "/"
                  ? authorSlug
                  : `/${authorSlug}`;

              return {
                name: `${firstName} ${lastName}`,
                href: `${LP_URL}/authors${parsedAuthSlug}` || null,
                img: image?.url || null,
              };
            })
          : null;

      return {
        ...(author ? { author: author?.[0] } : {}),
        category: {
          name: tagTitle,
          url: tagUrl,
        },
        tags,
        excerpt: excerpt || "",
        ...(featuredImage
          ? {
              image: {
                url: get(featuredImage, "url") || null,
                caption: get(featuredImage, "caption") || "",
                alt:
                  get(featuredImage, "alt") ||
                  get(featuredImage, "name") ||
                  null,
              },
            }
          : {}),
        esid,
        publishDate,
        readingTime,
        title,
        slug,
        href: `/${articleTypeSlug}/${slug}`,
        ...rest,
      };
    }) || [];

export default articleListItemsTransformation;
