import PropTypes from "prop-types";

import { ImgixNextImage as Image } from "@components/imgixNextImage";
import { tagular } from "@cohesion/tagular";

import { Placeholder } from "./placeholder";
import { Placeholder as MobilePlaceholder } from "../secondary/placeholder";

export function PrimaryCard({ loading = false, tagularCtx = {}, ...article }) {
  if (loading)
    return (
      <div className="rounded bg-black-100">
        <Placeholder className="hidden lg:block" />
        <MobilePlaceholder className="lg:hidden" />
      </div>
    );

  return (
    <div className="relative lg:rounded overflow-hidden lg:h-[688px]">
      <Image
        src={article.image.url}
        alt={article.image.alt || ""}
        width={730}
        height={630}
        imgixParams={{ w: 730, h: 630, fit: "crop" }}
        className="hidden object-cover w-full h-full rounded -z-10 lg:block"
      />
      <Image
        src={article.image.url}
        alt={article.image.alt || ""}
        width={780}
        height={425}
        imgixParams={{ w: 780, h: 425, fit: "crop" }}
        className="-z-10 rounded lg:hidden w-full aspect-[350/200]"
      />
      <div className="top-0 flex items-end w-full h-full pt-4 lg:absolute lg:bg-gradient-to-t from-transparent-80 via-transparent-40 to-transparent lg:p-8">
        <div className="lg:w-2/3 lg:pb-8">
          {article.tags && (
            <p className="relative z-10 uppercase label-sm text-black-400 lg:text-white">
              {article.tags[0]?.title}
            </p>
          )}
          <a
            href={article.href}
            className="inline-block my-2 font-semibold card-link heading-04 lg:text-white"
            onClick={() => {
              tagular("click", {
                actionOutcome: "internallink",
                outboundUrl: article.href,
                webElement: {
                  location: "section",
                  position: "lateststories",
                  text: article.title,
                  elementType: "link",
                  ...tagularCtx,
                },
              });
            }}
          >
            {article.title}
          </a>
          {article?.subhead && (
            <p className="relative text-black-400 lg:text-white">
              {article.subhead}
            </p>
          )}
          <p className="relative hidden mt-4 lg:block lg:text-white">
            {article.excerpt}
          </p>
        </div>
      </div>
    </div>
  );
}

PrimaryCard.propTypes = {
  loading: PropTypes.bool,
  tagularCtx: PropTypes.shape({}),
};
