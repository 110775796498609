/** Easier to build placeholders with skeletonreact.com */
import ContentLoader from "react-content-loader";

export function Placeholder(props) {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="0 0 400 100"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="9.93" rx="5" ry="5" width="143.55" height="86.59" />
      <rect x="150" y="9.67" rx="0" ry="0" width="100%" height="12.12" />
      <rect x="150" y="25.67" rx="0" ry="0" width="89" height="9" />
    </ContentLoader>
  );
}
