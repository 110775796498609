import PropTypes from "prop-types";
import cn from "classnames";

export function Tooltip({ children, className = "" }) {
  return (
    <div
      className={cn(
        "c-tooltip",
        "invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity",
        "bg-white rounded-md absolute w-contents min-w-64 right-0 translate-x-2 mt-3 px-4 pt-2 pb-3 shadow",
        className
      )}
    >
      <div>{children}</div>

      <style jsx>{`
        .c-tooltip::before {
          content: "";
          position: absolute;
          top: -20px;
          right: 7%;
          border-width: 10px;
          border-style: solid;
          border-color: transparent transparent white transparent;
        }
      `}</style>
    </div>
  );
}

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
};
