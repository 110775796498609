import PropTypes from "prop-types";
import cn from "classnames";
import { keyify } from "@utils/keyify";

export function Eyebrow({
  element: Elem = "h2",
  alt = false,
  className = "",
  nav = null,
  children,
}) {
  return (
    <Elem
      className={cn(
        "inline-block text-sm lg:text-base text-transparent bg-clip-text bg-gradient-to-r",
        alt ? "from-white" : "from-slate",
        "to-bit-pink font-mono uppercase tracking-wider",
        className
      )}
      data-anchor={keyify(children)}
      data-nav={nav}
    >
      {children}
    </Elem>
  );
}

Eyebrow.propTypes = {
  element: PropTypes.oneOf(["h2", "h3", "h4", "span"]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  alt: PropTypes.bool,
  nav: PropTypes.string,
};
