import PropTypes from "prop-types";
import cn from "classnames";

export function Eyebrow({
  href = null,
  className = "",
  light = false,
  children,
}) {
  const Elem = href ? "a" : "div";

  return (
    <Elem
      {...(href ? { href } : {})}
      className={cn(
        "text-sm uppercase font-semibold tracking-wide",
        className,
        {
          "text-black-200": light,
          "text-black-400": !light,
          "block hover:underline": href,
          block: !href,
        }
      )}
    >
      {children}
    </Elem>
  );
}

Eyebrow.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  light: PropTypes.bool,
};
