import articleListItemsTransformation from "@queries/global/transforms/articleListItemsTransformation";

/**
 * Making this available to the component which may refetch articles by tag
 */
export const sortLatestArticles = articles => {
  const [primary, ...rest] = articleListItemsTransformation(articles).map(
    ({ category, publishDate, readingTime, ...art }) => {
      let subhead = publishDate || "";
      if (readingTime) {
        subhead = publishDate ? `${subhead} • ${readingTime}` : readingTime;
      }
      return {
        ...art,
        subhead,
      };
    }
  );

  // prevent empty values from being returned
  const articleObj = {
    ...(primary ? { primary } : {}),
    ...(rest[0] ? { additional: rest } : {}),
  };
  return Object.keys(articleObj)[0] ? articleObj : null;
};
